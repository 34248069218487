import React, { useState, useRef } from "react";
import { IoLogoWechat, IoSend } from "react-icons/io5";
import { RiSendPlane2Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import "../index.css";
const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [conversation, setConversation] = useState([]); // Chat history
  const [inputValue, setInputValue] = useState(""); // User input
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  const [showContactPopup, setShowContactPopup] = useState(false); // Toggle new contact popup
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [formMessage, setFormMessage] = useState(""); // Form submission status message

  // Toggle chat popup visibility
  const togglePopup = () => setShowPopup(!showPopup);

  // Toggle contact popup visibility
  const toggleContactPopup = () => setShowContactPopup(!showContactPopup);

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle sending text message
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;

    const userMessage = { sender: "user", text: inputValue.trim() };
    setConversation((prev) => [...prev, userMessage]);

    setLoading(true);
    setError(null);
    setInputValue("");

    try {
      const response = await fetch(`${apiUrl}/chat`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: inputValue.trim() }),
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      const data = await response.json();
      const botMessage = {
        sender: "bot",
        text: data.response || "No response from server.",
      };

      setConversation((prev) => [...prev, botMessage]);
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to get response from server.");
    } finally {
      setLoading(false);
    }
  };

  // Handle contact form submission
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/send-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to send details.");
      }

      setFormMessage("Details sent successfully!");
      setFormData({ name: "", email: "", phone: "" });
    } catch (error) {
      console.error("Error:", error);
      setFormMessage("An error occurred while sending details.");
    }
  };

  return (
    <div>
      {/* Chat Button */}
      <div className="circle" onClick={togglePopup}>
        <IoLogoWechat className="chaticon" />
      </div>

      {/* Chat Popup */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div className="card">
              <IoMdClose onClick={togglePopup} className="closeicon" />
              <h2>Hi there 👋</h2>
              <p>Need help? Search our help center or start a conversation:</p>
            </div>

            <div className="chat-window">
              <div className="chat-messages">
                {conversation.length === 0 && (
                  <p className="typemessage">
                    Start the conversation by typing a message or uploading a
                    file.
                  </p>
                )}
                {conversation.map((msg, index) => (
                  <div
                    key={index}
                    className={`chat-message ${msg.sender === "user" ? "user-message" : "bot-message"
                      }`}
                  >
                    {msg.text}
                  </div>
                ))}
                {loading && <div className="bot">Bot is typing...</div>}
                {error && <div className="boterror">{error}</div>}
              </div>

              <div className="chat-input">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  autoFocus
                  placeholder="Type a message..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && inputValue.trim() && !loading) {
                      handleSendMessage();
                    }
                  }}
                  disabled={loading}
                />
                <RiSendPlane2Fill
                  onClick={handleSendMessage}
                  className={`send-icon ${inputValue.trim() && !loading
                    ? "text-white brightness-100"
                    : "text-gray-500 brightness-75"
                    }`}
                  disabled={loading}
                />
                {/* Contact Button */}
                <button className="contact-button" onClick={toggleContactPopup}>
                  Contact Us
                </button>

                {/* Contact Popup */}
                {showContactPopup && (
                  <div className="contact-popup">
                    <div className="contact-popup-content">
                      <div className="contact-card">
                        <IoMdClose
                          onClick={toggleContactPopup}
                          className="closeicon"
                        />
                        <h2>Contact Us</h2>
                      </div>

                      <form
                        className="contact-form"
                        onSubmit={handleFormSubmit}
                      >
                        <input
                          type="text"
                          name="name"
                          className="contact-name"
                          value={formData.name}
                          onChange={handleFormChange}
                          placeholder="Enter your name"
                          required
                        />
                        <input
                          type="email"
                          name="email"
                          className="contact-email"
                          value={formData.email}
                          onChange={handleFormChange}
                          placeholder="Enter your email"
                          required
                        />
                        <input
                          type="tel"
                          name="phone"
                          className="contact-phone"
                          value={formData.phone}
                          onChange={handleFormChange}
                          placeholder="Enter your phone number"
                          required
                        />
                        <button className="send-button" type="submit">Send</button>
                      </form>

                      {formMessage && (
                        <p className="form-message">{formMessage}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
